import React,{ useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import { downloadCsv, SECONDORY_COLOR } from '../common/sharedFunctions';
import { Select, MenuItem, Modal, Grid, Typography, Tooltip, CircularProgress } from '@mui/material';
import AlertDialog from "../components/AlertDialog";
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import Button from "components/CustomButtons/Button.js";
import TableStyle from 'components/Table/Style';
import localization from 'components/Table/Localization';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  submit3:{
    width:'100%',
    borderRadius:3,
    marginTop:2,
    padding:4
  },
  paper: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius:15,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function SubAdmins() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const {
    addUser,
    editUser, 
    checkUserExists,
    fetchUsersOnce,
    deleteUser,
    countries
  } = api;
  const [data, setData] = useState([]);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const [countryList, setCountryList] = useState();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [selectedImage, setSelectedImage] = useState(null); 
  const [userData, setUserData] = useState();
  const [profileModal, setProfileModal] =  useState(false);
  const [imageData, setImageData] =  useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const rootRef = useRef(null);
  const handleProfileModal = (e) => {
    setProfileModal(false);
    setSelectedImage(null);
  }

  const handleSetProfileModal = (e) =>{
    e.preventDefault();
    if(selectedImage){
      setLoading(true);
      let finalData = userData;
      finalData.profile_image = selectedImage;
      dispatch(editUser(finalData.id,finalData, "UpdateImage"));
      setProfileModal(false); 
      setTimeout(()=>{
        dispatch(fetchUsersOnce());
        setSelectedImage(null);
        setLoading(false); 
      },10000);
    }
    else{
      setCommonAlert({ open: true, msg: t('choose_image_first') })
    }
  }

  const onClick = (rowData) => {
    setImageData(rowData.profile_image);
    setProfileModal(true);
    setUserData(rowData);
  };

  useEffect(()=>{
    if(countries){
      let list = {};
      for(let i=0; i<countries.length; i++){
        let obj = countries[i];
        list[obj.label] = obj.label
      }
      setCountryList(list);
    }
  },[countries])

  useEffect(()=>{
    dispatch(fetchUsersOnce());
  },[dispatch,fetchUsersOnce]);

  useEffect(()=>{
    if(staticusers){
      setData(staticusers.filter(user => user.usertype ==='subadmin'));
    }else{
      setData([]);
    }
    loaded.current = true;
  },[staticusers]);

  const menuOptions = [
    {name : t('dashboard_text'), url:'/dashboard', pos:0},
    {name : t('booking_history'), url:'/bookings', pos:1},
    {name : t('addbookinglable'), url:'/addbookings', pos:2},
    {name : t('user'), url:'/users/0', pos:3},
    {name : t('car_type'), url:'/cartypes', pos:4},
    {name : t('cars'), url:'/cars', pos:5},
    {name : t('withdraws_web'), url:'/withdraws', pos:6},
    {name : t('add_to_wallet'), url:'/addtowallet', pos:7},
    {name : t('promo'), url:'/promos', pos:8},
    {name : t('push_notifications'), url:'/notifications', pos:9},
    {name : t('sos'), url:'/sos', pos:10},
    {name : t('complain'), url:'/complain', pos:11},
    {name : t('report'), url:'/allreports', pos:12},
    {name : t('settings_title'), url:'/settings', pos:13},
    {name : t('payment_settings'), url:'/paymentsettings', pos:14}
];

  const expandOpts = (mOpts) => {
      let str = "";
      for(let i=0;i<mOpts.length;i++){
        str = str + menuOptions[mOpts[i]].name + ", "
      }
      return str.substring(0, str.length - 2);
  }

  const columns = [
    { title: t('first_name'), field: 'firstName', initialEditValue: ''},
    { title: t('last_name'), field: 'lastName', initialEditValue: '', },
    { title: t('mobile'), field: 'mobile', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ?rowData.mobile : "Hidden for Demo"},
    { title: t('email'), field: 'email', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ?rowData.email : "Hidden for Demo",headerStyle:{textAlign:'center'}},
    { title: t('profile_image'), field: 'profile_image',initialEditValue: 'https://cdn.pixabay.com/photo/2021/07/25/08/03/account-6491185_1280.png', render: rowData => rowData.profile_image?<button onClick={()=>{onClick(rowData)}}><img alt='profile_image' src={rowData.profile_image} style={{width: 50}}/></button>:null, editable:'never',},
    { title: t('country'), field: 'country', lookup: countryList, editable:'onAdd' },
    {
        title: "Select ",
        field: 'menuOptions',
        render: rowData => expandOpts(rowData.menuOptions),
        editComponent: (props) => {
          const { value, onChange } = props;
          return (
           <Select
            labelId="labelId"
            id="selectId"
            multiple={true}
            value={value? value: []}
            onChange={(event) => {
              const {
                target: { value }
              } = event;
              onChange(typeof value === "string" ? value.split(",") : value);
            }}
          >
            { menuOptions.map((option) => (
              <MenuItem key={"key" + option.pos} value={option.pos}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        );      
        }
    }
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  return (
    !loaded.current? <CircularLoading/>:
    <>
    <MaterialTable
      title={t('subadmins')}
      columns={columns}
      style={{
        direction: isRTL === "rtl" ? "rtl" : "ltr",
        borderRadius: "8px",
        padding: "20px",
        boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
      }}
      data={data}
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 15, 20],
        exportCsv: (columns, data) => {
          let hArray = [];
          const headerRow = columns.map(col => {
            if (typeof col.title === 'object') {
              return col.title.props.text;
            }
            hArray.push(col.field);
            return col.title;
          });
          const dataRows = data.map(({ tableData, ...row }) => {
            row.createdAt = new Date(row.createdAt).toLocaleDateString() + ' '+ new Date(row.createdAt).toLocaleTimeString()
            let dArr = [];
            for(let i=0;i< hArray.length; i++) {
              dArr.push(row[hArray[i]]);
            }
            return Object.values(dArr);
          })
          const { exportDelimiter } = ",";
          const delimiter = exportDelimiter ? exportDelimiter : ",";
          const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
          const csvFileName = 'download.csv';
          downloadCsv(csvContent, csvFileName);
        },
        exportButton: {
          csv: settings.AllowCriticalEditsAdmin,
          pdf: false,
        },
        maxColumnSort: "all_columns",
        rowStyle: (rowData) => ({
          backgroundColor:
            selectedRow === rowData.tableData.id ? colors.ROW_SELECTED :colors.WHITE
        }),
        ...TableStyle()
      }}
      localization={localization(t)}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve,reject) => {
            setTimeout(() => {
              checkUserExists(newData).then((res) => {
                if (res.users && res.users.length > 0) {
                  alert(t('user_exists'));
                  reject();
                }
                else if(!(newData && newData.firstName)){
                  alert(t('proper_input_name'));
                  reject();
                }
                else if(res.error){
                  alert(t('email_or_mobile_issue'));
                  reject();
                }
                else if(!(newData.country)) {
                  alert(t('country_blank_error'));
                  reject();
                } else if(!(newData.menuOptions)){
                  alert(t('page_access'));
                  reject();
                }else {
                  newData['usertype'] = 'subadmin';
                  newData['createdAt'] = new Date().getTime();
                  dispatch(addUser(newData));
                  dispatch(fetchUsersOnce());
                  resolve();
                }
              });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
        settings.AllowCriticalEditsAdmin? new Promise((resolve,reject)=> {
            setTimeout(() => {
              if(!(newData && newData.firstName)){
                alert(t('proper_input_name'));
                reject();
              }else if(newData.menuOptions.length === 0){
                alert(t('page_access'));
                reject();
              }else {
                resolve();
                if(newData !== oldData){
                  delete newData.tableData;
                  dispatch(editUser(oldData.id,newData));
                  dispatch(fetchUsersOnce());
                }
              }
            }, 600);
          }):new Promise(resolve => {
            setTimeout(() => {
              resolve();
              setCommonAlert({ open: true, msg: t('demo_mode') });
            }, 600);
          }),
          onRowDelete: (oldData) =>
            settings.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    dispatch(deleteUser(oldData.id));
                    dispatch(fetchUsersOnce());
                  }, 600);
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    alert(t("demo_mode"));
                  }, 600);
                }), 
      }}
      
    />
     <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={profileModal}
        onClose={handleProfileModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
      <Grid container spacing={1} className={classes.paper} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="h1" variant="h6">
                {t('profile_image')}
              <input
                  type="file"
                  style={{marginLeft:10}}
                  name= {t('image')}
                  onChange={(event) => {
                      setSelectedImage(event.target.files[0]);
                  }}
              />
            </Typography>
         </Grid>
         <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectedImage  && !loading ? 
                    <Tooltip title={t('cancel')}>
                      <CancelIcon onClick={()=>setSelectedImage(null)} style={{ fontSize: 30,backgroundColor:colors.RED,borderRadius:50,color:colors.WHITE}}  />
                    </Tooltip>
                  : null }
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectedImage ? 
                   <img alt="not fount" width={"200px"} height={"200px"}  src={URL.createObjectURL(selectedImage)} style={{marginTop:15,marginBottom:20}}/>
                   :
                    <img alt="not fount" width={"200px"} height={"200px"}  src={imageData} style={{marginTop:10}}/>
                  }
                <br />
                </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
          {loading ? 
            <Grid
              container
              spacing={0}
              alignItems="center"
              justify="center"
              style={{ minHeight: '5vh' }} >
              <CircularProgress/>
            </Grid>
            :
            <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
            <Button onClick={handleProfileModal} variant="contained" color="danger">
              {t('cancel')}
            </Button>
            <Button onClick={handleSetProfileModal} variant="contained" color="success" style={{marginLeft:10}}>
              {t('save')}
            </Button>
            </Grid>
          }
          </Grid>
        </Grid>
      </Modal>
     <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </>
  );
}