import React, { useState, useEffect } from "react";
import { Typography, Grid, Card, Avatar, Button, TextField, Box,Modal } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { colors } from "../components/Theme/WebTheme";
import CircularLoading from "../components/CircularLoading";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AlertDialog from "../components/AlertDialog";
import { api } from "common";
import { MAIN_COLOR, SECONDORY_COLOR, FONT_FAMILY } from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";
import GoBackButton from "components/GoBackButton";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "10px",
    backgroundColor: SECONDORY_COLOR,
    minHeight: 60,
    minWidth: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: 2,
    marginBottom: 10,
    boxShadow: `0px 2px 5px ${MAIN_COLOR}`,
  },
  avatar: {
    width: 300,
    height: 250,
    display: "flex",
    flexDirection: "column",
    boxShadow: 3,
    border: `2px dashed ${colors.TABLE_BORDER}`,
    fontSize: 16,
    background: "none",
    color: "inherit",
    fontWeight: "bold",
  },
  buttonStyle: {
    borderRadius: "19px",
    minHeight: 50,
    minWidth: 150,
    marginBottom: 20,
    marginTop: 20,
    textAlign: "center",
    cursor: "pointer",
    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
    fontFamily: FONT_FAMILY,
    "&:hover": {
      backgroundColor: MAIN_COLOR,
    },
  },
  buttonStyle1: {
    borderRadius: "19px",
    minHeight: 50,
    minWidth: 150,
    marginBottom: 20,
    textAlign: "center",
    cursor: "pointer",
    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
    fontFamily: FONT_FAMILY,
    "&:hover": {
      backgroundColor: MAIN_COLOR,
    },
  },
  modalStyle:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height:600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border:'none',
    p: 4,
  }
}));

function UserDocuments() {
  const { id, rId } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settingsdata.settings);
  const staticusers = useSelector((state) => state.usersdata.staticusers);
  const { fetchUsersOnce, updateLicenseImage, editUser, updateExpairDocument2 } = api;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [images, setImages] = useState({
    idImage: null,
    licenseImageFront: null,
    licenseImageBack: null,
    vehicleRegistation: null,
    photoVehiclePlate: null,
    addressImage: null,
  });

  const resetImages = () => {
    setImages({
      idImage: null,
      licenseImageFront: null,
      licenseImageBack: null,
      vehicleRegistation: null,
      photoVehiclePlate: null,
      addressImage: null,
    });
  };
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const classes = useStyles();
  const [expiryDates, setExpiryDates] = useState({
    vehicleLicenseExpiry: null,
    vehicleInsuranceExpiry: null,
    registationExpiry: null,
  });

  const [imageModal, setImageModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const handleOpen = (data) => {
      setSelectedData(data); 
      setImageModal(true);
  };
  const handleClose = () => {
    setImageModal(false);
  };

  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  useEffect(() => {
    if (staticusers) {
      const user = staticusers.find((user) => user.id === id.toString());
      if (!user) {
        navigate("/404");
      } else {
        setData(user);
      }
    }
  }, [staticusers, id, navigate]);

  useEffect(() => {
    if (data) {
      const filteredDates = {
        vehicleLicenseExpiry: data.vehicleLicenseExpiry || null,
        vehicleInsuranceExpiry: data.vehicleInsuranceExpiry || null,
        registationExpiry: data.registationExpiry || null
      };

      setExpiryDates(filteredDates);
    }
  }, [data]);

  const handleImageChange = (field) => (e) => {
    setImages(prev => ({ ...prev, [field]: e.target.files[0] }));
  }

  const handleExpiryDateChange = (field, newValue) => {
    setExpiryDates(prev => ({
      ...prev,
      [field]: newValue ? newValue.valueOf() : null,
    }));
  };

  const getMinDate = () => {
    if (!expiryDates) return null;
    let obj = { ...expiryDates };
    let dateFields = Object.entries(obj);
    let nonNullDates = dateFields.filter(([key, value]) => value !== null);
    if (nonNullDates.length === 0) return null;
    nonNullDates.sort((a, b) => a[1] - b[1]);
    return {
      docname: nonNullDates[0][0],
      date: nonNullDates[0][1]
    };
  };

  const handleSaveUser = () => {
    setLoading(true);
    let isAnyIdUploaded = false;

    if (settings.AllowCriticalEditsAdmin) {
      Object.entries(images).forEach(([field, image]) => {
        if (image) {
          isAnyIdUploaded = true;
          dispatch(updateLicenseImage(data.id, image, field));
        }
      });

      if (data.usertype === "driver" && id) {
        if (Object.values(expiryDates).some(date => date !== null)) {
          isAnyIdUploaded = true;
          const updatedData = { ...data, ...expiryDates };
          dispatch(editUser(data.id, updatedData));
          const minDate = getMinDate();
          if (minDate) {
            updatedData.lastexapir = minDate.date;
            updateExpairDocument2(id, { docdate: minDate.date, docname: minDate.docname });
            dispatch(editUser(data.id, updatedData));
          }
        }

      }
      if (!isAnyIdUploaded) {
        setCommonAlert({ open: true, msg: t("no_doc_uploaded") });
      }

      setTimeout(() => {
        resetImages();
        setEditable(false);
        setLoading(false);
        dispatch(fetchUsersOnce());
      }, 3000);
    } else {
      setCommonAlert({ open: true, msg: t("demo_mode") });
      setLoading(false);
    }
  };

  const handleCancel = () => {
    resetImages();
    setEditable(false);
  };

  const commonFields = [
    { title: t("verifyid_image"), imageUrl: data?.verifyIdImage, uploadPlaceholder: t("upload_id_details"), field: "verifyIdImage",  },
  ];

  const driverFields = [
    { title: t("license_image_front"), imageUrl: data?.licenseImage, uploadPlaceholder: t("upload_driving_license_front"), field: "licenseImage", expiryField: "vehicleLicenseExpiry", expairyLebel: t("license_expiryDtae") },
    { title: t("license_image_back"), imageUrl: data?.licenseImageBack, uploadPlaceholder: t("upload_driving_license_back"), field: "licenseImageBack", expiryField: "vehicleInsuranceExpiry", expairyLebel: t("insurance_expiryDtae") },
    { title: t("vehicle_registation"), imageUrl: data?.vehicleRegistation, uploadPlaceholder: t("upload_registration"), field: "vehicleRegistation", expiryField: "registationExpiry", expairyLebel: t("registation_expiryDtae") },
    { title: t("photo_vehicle_plate_required"), imageUrl: data?.photoVehiclePlate, uploadPlaceholder: t("upload_insurence"), field: "photoVehiclePlate" },
    { title: t("address_proof"), imageUrl: data?.addressImage, uploadPlaceholder: t("upload_address_proof"), field: "addressImage" },
  ];

  const imageFields = data?.usertype === "driver" ? [...driverFields, ...commonFields ] : commonFields;

  const ExpiryDateField = ({ label, value, onChange, disabled, onClick }) => {
    const { i18n } = useTranslation();
    const isRTL = i18n.dir() === "rtl";

    const handleClick = (event) => {
      if (disabled && onClick) {
        event.preventDefault();
        event.stopPropagation();
        onClick();
      }
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disablePast
          label={label}
          value={value || null}
          onChange={(newValue) => onChange(newValue)}
          disabled={disabled}
          sx={{
            direction: isRTL ? "rtl" : "ltr",
            '& .MuiInputBase-root': {
              cursor: disabled ? 'pointer' : 'text',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              style={{ marginTop: 10 }}
              onClick={handleClick}
            />
          )}
        />
      </LocalizationProvider>
    );
  };

  const ImageCard = ({ title, imageUrl, placeholder, onClick, expiryField, expiryLabel }) => (
     <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
      <Card className={classes.card}>
        <Typography style={{ textAlign: "center", fontSize: 16, fontWeight: "bold", fontFamily: FONT_FAMILY }}>
          {title}
        </Typography>
      </Card>
      <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {imageUrl ? (
          <Avatar alt={title} src={imageUrl} sx={{ width: 300, height: 250, borderRadius: "19px", cursor: "pointer" }} variant="square" onClick={onClick} />
        ) : (
          <Avatar className={classes.avatar} variant="square" sx={{ boxShadow: 3, cursor: "pointer", fontFamily: FONT_FAMILY, textAlign: "center" }} onClick={onClick}>
            {placeholder}
          </Avatar>
        )}
      </Grid>
      {expiryField && (
        <ExpiryDateField
          label={expiryLabel}
          value={data[expiryField]}
          onChange={(newValue) => handleExpiryDateChange(expiryField, newValue)}
          disabled={!editable}
          onClick={() => setEditable(true)}
        />
      )}
    </Grid>
  );
  
  const ImageUpload = ({ image, onClick, altText, uploadText, expiryField, expiryLabel, idField }) => {
    return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
      {image ? (
        <div onClick={onClick} style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
          <img src={URL.createObjectURL(image)} alt={altText} style={{ width: 300, height: 250, borderRadius: "19px" }} />
        </div>
      ) : (
        <div onClick={onClick} style={{ display: "flex", justifyContent: "center", marginTop: 30, cursor: "pointer" }}>
          <Avatar className={classes.avatar} sx={{ boxShadow: 3 }} variant="square">
            <FileUploadIcon sx={{ fontSize: 100, marginBottom: 3, color: colors.Header_Text_back, fontFamily: FONT_FAMILY }} />
            <Typography sx={{ textAlign: "center" }} fontFamily={FONT_FAMILY}>{uploadText}</Typography>
          </Avatar>
        </div>
      )}
      {expiryField && (
        <ExpiryDateField
          label={expiryLabel}
          value={expiryDates[expiryField]}
          onChange={(newValue) => handleExpiryDateChange(expiryField, newValue)}
          disabled={false}
          onClick={() => setEditable(true)}
        />
      )}
    </Grid>
  );}

  const EditButtonGroup = ({ editable, onEdit, onSave, onCancel }) => (
    <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
      {!editable ? (
        <Button
          className={classes.buttonStyle}
          sx={{
            backgroundColor: MAIN_COLOR,
            width: "50%",
            cursor: "pointer",
            borderColor: colors.CARD_DETAIL,
          }}
          variant="contained"
          onClick={onEdit}
        >
          <Typography sx={{ textAlign: "center", fontSize: 16, fontWeight: "bold", fontFamily: FONT_FAMILY, color: colors.WHITE }}>
            {t("edit")}
          </Typography>
        </Button>
      ) : (
        <>
          <Button
            className={classes.buttonStyle}
            sx={{ backgroundColor: colors.GREEN, width: "40%", "&:hover": { backgroundColor: MAIN_COLOR } }}
            variant="contained"
            onClick={onSave}
          >
            <Typography sx={{ color: colors.WHITE, textAlign: "center", fontSize: 16 }}>{t("save")}</Typography>
          </Button>
          <Button className={classes.buttonStyle} sx={{ backgroundColor: colors.RED, width: "40%" }} variant="contained" onClick={onCancel}>
            <Typography sx={{ color: colors.WHITE, textAlign: "center", fontSize: 16 }}>{t("cancel")}</Typography>
          </Button>
        </>
      )}
    </div>
  );

  return loading ? (
    <CircularLoading />
  ) : (
    <Card sx={{ borderRadius: "19px", backgroundColor: colors.WHITE, minHeight: 200, marginTop: 1, marginBottom: 1, padding: 1 }}>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: isRTL === "rtl" ? "flex-end" : "flex-start" }}>
        <Typography variant="h5" sx={{ margin: "10px 10px 0 5px", fontFamily: FONT_FAMILY }}>{t("documents_title")}</Typography>
        <GoBackButton isRTL={isRTL} onClick={() => navigate(`/users/${rId}`, { state: { pageNo: state?.pageNo } })} />
      </div>
      <Grid container spacing={1} direction="column" sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          <Grid container spacing={1} justifyContent="center" alignItems="center" marginY={10} gap={2}>
            {editable ? (
              imageFields?.map((field, index) => (
                <ImageUpload
                  key={index}
                  image={images[field.field]}
                  onClick={() => document.getElementById(field.field).click()}
                  altText={field.title}
                  uploadText={t(field.uploadPlaceholder)}
                  expiryField={field.expiryField}
                  expiryLabel={t(field.expairyLebel)}
                  idField={field.idField}
                />
              ))
            ) : (
              imageFields?.map((field, index) => (
                <ImageCard
                  key={index}
                  title={field.title}
                  imageUrl={field.imageUrl}
                  placeholder={field.title}
                  // onClick={() => setEditable(true)}
                  onClick={() => handleOpen(field.imageUrl)}

                  expiryField={field.expiryField}
                  expiryLabel={t(field.expairyLebel)}
                  idField={field.idField}
                />
              ))
            )}
          </Grid>
        <EditButtonGroup editable={editable} onEdit={() => setEditable(true)} onSave={handleSaveUser} onCancel={handleCancel} />
      </Grid>
      <Modal
        open={imageModal}
        onClose={handleClose}
        
      >
        <Box className={classes.modalStyle} sx={{backgroundColor:'white',}}>
        {selectedData  ? 
        <img
               
                width={"100%"}
                height={"90%"}
                src={selectedData}
                style={{}}
              />
              :
              <Typography>no</Typography>
        }
         <Button className={classes.buttonStyle1} sx={{ backgroundColor: colors.RED, width: "20%" }} variant="contained" onClick={handleClose}>
            <Typography sx={{ color: colors.WHITE, textAlign: "center", fontSize: 16 }}>{t("cancel")}</Typography>
          </Button>
        </Box>
        
      </Modal>
      {imageFields?.map((field) => (
        <input key={field.field} id={field.field} type="file" hidden onChange={handleImageChange(field.field)} />
      ))}
      <AlertDialog open={commonAlert.open} onClose={() => setCommonAlert({ open: false, msg: "" })}>
        {commonAlert.msg}
      </AlertDialog>
    </Card>
  );
}

export default UserDocuments;
